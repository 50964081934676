import moment from "moment";

import HeadNav from "../../components/head-nav/index.vue";
import RefundRulePop from './components/refund-rule-pop/index.vue'
// 订单详情
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_queryHotelOrderDetailsForOrderCenter";

export default {
  components: {
    HeadNav,
    RefundRulePop
  },
  data() {
    return {
      loading: false,
      detail: {
        order: {
          refundRule: {},
        },
        personList: [],
      },
      status: "",
    };
  },
  methods: {
    req_detail() {
      this.loading = true;
      req_detail_data({
        orderNo: this.$route.query.orderNo,
      })
        .then((res) => {
          res.orderInterimResult.refundRule = res.orderInterimResult.refund;
          let detail = {
            applyId: res.applyId,
            order: res.orderInterimResult,
            evectionInfo: res.tusEvection,
            personList: res.hotelOrderPersonList ? res.hotelOrderPersonList : [],
            approvalReason: res.approvalReason,
          };
          detail.order.hotelGroup = res.hotelGroup;
          detail.order.ifAgreement = res.ifAgreement;
          detail.order.cardNumber = res.cardNumber;
          detail.order.agreementHotelText = res.agreementHotelText;

          this.detail = detail;
          this.detail.order.paymentChanelText = this.detail.order.paymentChanelText ? this.detail.order.paymentChanelText : '---';
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    to_list() {
      this.$router.back();
    },
    goEvectionApplyDetail() {
      this.$router.push({
        name: 'admin-my-apply-manage-apply-info',
        query: {
          applyId: parseInt(this.detail.applyId),
          approveType: 1,
          type: 'info',
          lastMenu: 'adminMyApply'
        }
      })
    },
    // 订单状态
    getStatusColor(val) {
      if (val === 0 || val === 2 || val === 18) {
        return 'orange'
      } else if (val === 11) {
        return 'green'
      } else {
        return 'red'
      }
    },
  },
  mounted() {},
  activated() {
    this.req_detail();
  },
  filters: {
    format_time(val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    format_create(val) {
      switch (Number(val)) {
        case 1:
          return "因私出行";
          break;
        case 2:
          return "因公出差";
          break;
      }
    },
    format_status(val, evectionType) {
      const type = Number(evectionType);
      if (type === 1) {
        switch (Number(val)) {
          case 0:
            return "退订中";
            break;
          case 1:
            return "退订成功";
            break;
          case 2:
            return "待平台确认";
            break;
          case 3:
            return "退订失败";
            break;
          case 4:
            return "未退订，待审批";
            break;
        }
      } else if (type === 2) {
        switch (Number(val)) {
          case 0:
            return "退订审核中";
            break;
          case 1:
            return "退订成功";
            break;
          case 2:
            return "待平台确认";
            break;
          case 3:
            return "退订失败";
            break;
          case 4:
            return "未退订，待审批";
            break;
        }
      }
    },
    format_payType(val) {
      switch (Number(val)) {
        case 1:
          return "个人支付";
          break;
        case 2:
          return "企业支付";
          break;
      }
    },
    format_week(val) {
      if (val) {
        switch (moment(val).day()) {
          case 1:
            return "周一";
            break;
          case 2:
            return "周二";
            break;
          case 3:
            return "周三";
            break;
          case 4:
            return "周四";
            break;
          case 5:
            return "周五";
            break;
          case 6:
            return "周六";
            break;
          case 0:
            return "周日";
            break;
        }
      } else {
        return "";
      }
    },
    format_class(val) {
      switch (val) {
        case 0:
          return "notstart";
          break;
        case 1:
          return "pending";
          break;
        case 2:
          return "success";
          break;
        case 3:
          return "fail";
          break;
        case 4:
          return "failure";
          break;
      }
    },
    format_icon(val) {
      switch (val) {
        case 0:
          return "haolv-iconfont haolv-iconwait";
          break;
        case 1:
          return "haolv-iconfont haolv-iconwait";
          break;
        case 2:
          return "haolv-iconfont haolv-iconsuccess";
          break;
        case 3:
          return "haolv-iconfont haolv-iconwait";
          break;
        case 4:
          return "haolv-iconfont haolv-iconwait";
          break;
      }
    },
    format_status_text(val) {
      switch (val) {
        case 0:
          return "未开始";
          break;
        case 1:
          return "审批中";
          break;
        case 2:
          return "审批通过";
          break;
        case 3:
          return "已拒绝";
          break;
        case 4:
          return "失效";
          break;
      }
    },
    format_cardType(val) {
      switch (val) {
        case 1:
          return "身份证";
          break;
        case 2:
          return "护照";
          break;
        case 4:
          return "回乡证";
          break;
        case 5:
          return "台胞证";
          break;
        case 6:
          return "军官证";
          break;
        case 7:
          return "香港身份证";
          break;
        case 11:
          return "澳门身份证";
          break;
        case 10:
          return "其它";
          break;
      }
    },
  },
};
